import { lazy } from "react"
import { Route, Switch } from "react-router-dom"
import { remove } from "store/actions/alert"
import { useAppDispatch } from "hooks"
import { useEffect} from "react"

// const Home = lazy(() => import("pages/Home"))
const Login = lazy(() => import("pages/Auth/Login"))
const ForgotPassword = lazy(() => import("pages/Auth/ForgotPassword"))
const Dashboard = lazy(() => import("pages/Dashboard"))
const UserList = lazy(() => import("pages/User/List"))
const UserAdd = lazy(() => import("pages/User/Add"))
const FirstTimeResetPassword = lazy(
  () => import("pages/Auth/FirstTimeResetPassword/FirstTimeResetPassword")
);
const MyAccount = lazy(() => import("pages/Auth/MyAccount"));
const Options = lazy(() => import("pages/Project/Add/OptionsRequest"));
const GenerateProject = lazy(
  () => import("pages/Project/Add/GenerateProject")
)
const ProjectList = lazy(() => import('pages/Project/List'))
const ProjectEdit = lazy(() => import('pages/Project/Edit'))
const ProjectSchedule = lazy(() => import('pages/Project/Schedules/Schedule'))
const ComingSoon = lazy(() => import('pages/OtherPage/ComingSoon'))
const Page403 = lazy(() => import('pages/OtherPage/Page403'))
const Page404 = lazy(() => import('pages/OtherPage/Page404'))
const Tracking = lazy(() => import('pages/Tracking/TrackingPage'))
const DetailSample = lazy(() => import('pages/Tracking/DetailSample'))
const ListTestingRequest = lazy(() => import('pages/Project/TestingRequests/ListTestingRequest'))
const TestingDetail = lazy(() => import('pages/Project/TestingRequests/TestingDetai'))
const EnquiryList = lazy(() => import('pages/Enquiry/List'))
const EnquiryAdd = lazy(() => import('pages/Enquiry/Add'))
const EnquiryDetail = lazy(() => import('pages/Enquiry/Detail'))
const UploadReport = lazy(() => import('pages/Project/TestingRequests/UploadReport'))
const BusinessEdit = lazy(() => import('pages/Auth/Business/Edit'))
const DataUnavailable = lazy(() => import("pages/OtherPage/DataUnavailable"));
const LoginRedirect = () => {
  useEffect(() => {
    window.location.replace("/"); // Redirects to "/"
  }, []);
  return null; // Component renders nothing
};

// Defines a type for individual route objects
type routeItem = {
  path: string, // URL path for the route
  key: string, // Unique identifier for the route
  exact: boolean, // Specifies if the route should match the URL exactly or partially
  component: Function, // Component to be rendered when the route is accessed
  requiredAuth: boolean, // Determines whether the user needs to be authenticated to access the route
  name: string, // Name of the route
  title: string, // Title of the route (will be displayed in the browser tab)
}

// Defines a type for nested routes that extends routeItem type
type routes = routeItem & {
  routes?: routeItem[] // Optional array of routeItems denoting any nested routes.
}


const ROUTES: routes[] = [
  // Home Route
  {
    path: "/", // URL pattern for the home route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: Login, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: false, // Indicates whether authentication is required or not
    name: "login", // Identifier for this route
    title: "Login", // Title that will be displayed in the header
  },
  {
    path: "/login",
    key: "LOGIN_REDIRECT",
    exact: true,
    component: LoginRedirect,
    routes: [],
    requiredAuth: false,
    name: "login-redirect",
    title: "Redirect to Login",
  },

  // Forgot Password Route
  {
    path: "/forgot-password", //URL pattern for the forgot password route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ForgotPassword, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: false, // Indicates whether authentication is required or not
    name: "forgot-password", // Identifier for this route
    title: "Forgot Password", // Title that will be displayed in the header
  },

  // Dashboard Route
  {
    path: "/dashboard", // URL pattern for the dashboard route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: Dashboard, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "dashboard", // Identifier for this route
    title: "Dashboard", // Title that will be displayed in the header
  },

  // Users Route
  {
    path: "/users", // URL pattern for the users route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: UserList, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "users-list", // Identifier for this route
    title: "Account Management", // Title that will be displayed in the header
  },

  // Add User Route
  {
    path: "/users/add", // URL pattern for the add user route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: UserAdd, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "user-add", // Identifier for this route
    title: "Add New Account", // Title that will be displayed in the header
  },

  // Users View Route
  {
    path: "/user/:id", // URL pattern for the users view route with a parameter id
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: Page404, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "users-view", // Identifier for this route
    title: "User View", // Title that will be displayed in the header
  },

  // Users Edit Route
  {
    path: "/user/:id/edit", // URL pattern for the users edit route with a parameter id
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: Page404, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "users-edit", // Identifier for this route
    title: "User Edit", // Title that will be displayed in the header
  },

  // Lock Route
  {
    path: "/lock", // URL pattern for the lock route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: Page403, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "lock", // Identifier for this route
    title: "Lock", // Title that will be displayed in the header
  },

  // First Time Reset Password Route
  {
    path: "/first-time-reset-password", // URL pattern for the first time reset password route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: FirstTimeResetPassword, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: false, // Indicates whether authentication is required or not
    name: "first-time-reset-password", // Identifier for this route
    title: "First Time Reset Password", // Title that will be displayed in the header
  },

  // // Enquiries List Route
  // {
  //   path: "/enquiries", // URL pattern for the enquiries list route
  //   key: "ROOT", // Unique identifier for this component instance
  //   exact: true, // Exact match is required to load the component
  //   component: EnquiryList, // Component that will be loaded on this route
  //   routes: [], // Optional array of child routes if any
  //   requiredAuth: true, // Indicates whether authentication is required or not
  //   name: "enquiries", // Identifier for this route
  //   title: "Enquiries", // Title that will be displayed in the header
  // },

  // // Add Enquiry Route
  // {
  //   path: "/enquiries/add", // URL pattern for the add enquiry route
  //   key: "ROOT", // Unique identifier for this component instance
  //   exact: true, // Exact match is required to load the component
  //   component: EnquiryAdd, // Component that will be loaded on this route
  //   routes: [], // Optional array of child routes if any
  //   requiredAuth: true, // Indicates whether authentication is required or not
  //   name: "enquiry-add", // Identifier for this route
  //   title: "Add Enquiry", // Title that will be displayed in the header
  // },

  // // Enquiry Detail Route
  // {
  //   path: "/enquiry/:id", // URL pattern for the enquiry detail route with a parameter id
  //   key: "ROOT", // Unique identifier for this component instance
  //   exact: true, // Exact match is required to load the component
  //   component: EnquiryDetail, // Component that will be loaded on this route
  //   routes: [], // Optional array of child routes if any
  //   requiredAuth: false, // Indicates whether authentication is required or not
  //   name: "enquiry", // Identifier for this route
  //   title: "Enquiry Detail", // Title that will be displayed in the header
  // },

  // Project Requests Route
  {
    path: "/project-requests", // URL pattern for the project requests route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: Options, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "project-requests", // Identifier for this route
    title: "Enquiry", // Title that will be displayed in the header
  },

  // Notifications Route
  {
    path: "/notifications", // URL pattern for the notifications route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ComingSoon, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "notifications", // Identifier for this route
    title: "Notifications", // Title that will be displayed in the header
  },

  // Reports Route
  {
    path: "/reports", // URL pattern for the reports route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ComingSoon, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "reports", // Identifier for this route
    title: "Reports", // Title that will be displayed in the header
  },

  // Messages Route
  {
    path: "/messages", // URL pattern for the messages route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ComingSoon, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "messages", // Identifier for this route
    title: "Messages", // Title that will be displayed in the header
  },

  // Settings Route
  {
    path: "/settings", // URL pattern for the settings route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ComingSoon, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "settings", // Identifier for this route
    title: "Settings", // Title that will be displayed in the header
  },

  // Account Route
  {
    path: "/account", // URL pattern for the account route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ComingSoon, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: false, // Indicates whether authentication is required or not
    name: "account", // Identifier for this route
    title: "Account", // Title that will be displayed in the header
  },

  // FAQs Route
  {
    path: "/faqs", // URL pattern for the faqs route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ComingSoon, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: false, // Indicates whether authentication is required or not
    name: "faqs", // Identifier for this route
    title: "FAQs", // Title that will be displayed in the header
  },

  // My Account Route
  {
    path: "/my-account", // URL pattern for the my account route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: MyAccount, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "my-account", // Identifier for this route
    title: "My Account", // Title that will be displayed in the header
  },

  // Generate Project Route
  {
    path: "/project/add", // URL pattern for the generate project route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: GenerateProject, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    name: "project/add", // Identifier for this route
    title: "Project Request Form", // Title that will be displayed in the header
  },

  // Project List Route
  {
    path: "/projects", // URL pattern for the project list route
    name: "project-list", // Identifier for this route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ProjectList, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    title: "Project Management", // Title that will be displayed in the header
  },

  // Project Edit Route
  {
    path: "/projects/:id/edit", // URL pattern for the project edit route
    name: "project-edit", // Identifier for this route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ProjectEdit, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    title: "Update Project Information", // Title that will be displayed in the header
  },

  // Project Schedule Route
  {
    path: "/project/:id/schedule", // URL pattern for the project schedule route
    name: "project-schedule", // Identifier for this route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ProjectSchedule, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    title: "Create Testing Request", // Title that will be displayed in the header
  },

  // Testing Requests Route
  {
    path: "/project/:id/testing-requests", // URL pattern for the testing requests route
    name: "testing-requests", // Identifier for this route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: ListTestingRequest, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    title: "Testing Request", // Title that will be displayed in the header
  },

  // Testing Detail Route
  {
    path: "/projects/:id/testing-detail/:request_id", // URL pattern for the testing detail route
    name: "testing-detail", // Identifier for this route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: TestingDetail, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    title: "Testing Request Detail", // Title that will be displayed in the header
  },

  // Upload Report Route
  {
    path: "/projects/:id/testing-detail/:request_id/upload", // URL pattern for the upload report route
    name: "testing-report-upload", // Identifier for this route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: UploadReport, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    title: "Testing Reports", // Title that will be displayed in the header
  },

  // Tracking Route
  {
    path: "/tracking", // URL pattern for the tracking route
    name: "tracking", // Identifier for this route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: Tracking, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    title: "Tracking", // Title that will be displayed in the header
  },

  // Detail Sample Route
  {
    path: "/tracking/:id", // URL pattern for the detail sample route
    name: "detail-sample", // Identifier for this route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: DetailSample, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    title: "Tracking", // Title that will be displayed in the header
  },

  // Page 403 Route
  {
    path: "/403", // URL pattern for the page 403 route
    name: "permission-denined", // Identifier for this route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: Page403, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    title: "Page403", // Title that will be displayed in the header
  },

  // Business Edit Route
  {
    path: "/business-edit", // URL pattern for the business edit route
    name: "business-edit", // Identifier for this route
    key: "ROOT", // Unique identifier for this component instance
    exact: true, // Exact match is required to load the component
    component: BusinessEdit, // Component that will be loaded on this route
    routes: [], // Optional array of child routes if any
    requiredAuth: true, // Indicates whether authentication is required or not
    title: "Update Business Information", // Title that will be displayed in the header
  },
  {
    path: "/data-unavailable", // URL path with wildcard parameter for user ID
    name: "data-unavailable", // identifier for this route
    title: "Data unavailable", // title that will be displayed in the header
    key: "ROOT", // unique identifier for this component instance
    exact: true, // exact match is required to load the component
    component: DataUnavailable, // component that will be loaded on this route
    routes: [], // child routes if any
    requiredAuth: true, // only authenticated users can access this route
  },
];

// Exporting an object that contains all the routes for the application
export default ROUTES

// Exporting a function called RenderRoutes that accepts an object with an array of routes as a parameter
export function RenderRoutes({ routes }: { routes: routes[] }) {
  return (
    // Using a switch statement to render only one route at a time
    <Switch>
      {/* Using the Array.map method to map over each route and create a new RouteWithSubRoutes component for each one */}
      {routes.map((route, i) => {
        return <RouteWithSubRoutes {...route} />
      })}

      {/* Adding a wildcard (*) route at the end that will be rendered if none of the other routes match */}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  )
}

// A function for rendering a route and its sub-routes in the application
function RouteWithSubRoutes(route: routes) {
  // Initialize the Redux dispatch hook
  const dispatch = useAppDispatch()

  // Set the document title to match the title defined in the route object
  document.title = route.title

  // Return a Route component with the given path, exact flag, and rendering function
  // The rendering function checks for authentication status and redirects the user as needed
  return (
    <Route
      key={route.key}
      path={route.path}
      exact={route.exact}
      render={(props) => {
        // Clear any existing Redux state when the page loads
        dispatch(remove())

        // Store the current route name in local storage
        localStorage.setItem("currentRoute", route.name);

        if (!localStorage.getItem("token")) {
          // If the user is not authenticated and the route requires authentication, redirect to the login page
          if (route.requiredAuth) {
            window.location.href = "/?continue=" + encodeURIComponent(window.location.href);
          }
        } else {
          // If the user is authenticated and the route does not require authentication, redirect to the dashboard
          if (["/", "/register", "/forgot-password"].includes(route.path)) {
            window.location.href = "/dashboard"
          }
        }

        // Render the given component and pass down the props and sub-routes
        return <route.component {...props} routes={route.routes} routeName={route.name} />
      }}
    />
  );
}